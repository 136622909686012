import styled from 'styled-components';

const MapSectionStyle = styled.section`
	--lottieWidth: 100%;
	--paddingContainer: var(--sp13x);
	--sizeMetric: var(--sp1-5x);
	--distanceMetricText: var(--sp2-5x);

	.row {
		padding: var(--paddingContainer) 0;
	}

	.left-section {
		display: flex;
		justify-content: center;
		align-items: center;

		.info-wrapper {
			display: flex;
			flex-direction: column;

			.title {
				margin-bottom: var(--distanceTitle);
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.button-styles {
				z-index: 1;
			}

			.description {
				/* display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden; */
				margin-bottom: var(--distanceDescription);
			}
		}
	}

	.distance-icon-wrapper {
		display: flex;
		flex-direction: column;
		gap: var(--sp1x);
	}

	.right-section {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.lottie-wrapper {
		width: var(--lottieWidth);
	}

	.wrapper-item-metrica {
		margin-top: var(--distanceDescription);
		.icon-metric {
			display: flex;
			align-items: center;
			gap: var(--distanceMetricText);
			transform: translateY(200%);
			opacity: 0;

			i {
				font-size: var(--sizeMetric);
				color: var(--orange1000Color);
			}
		}

		.icon-metric-orange {
			i {
				color: var(--whiteColor);
			}
		}
	}
	.button-wrapper-map {
		width: fit-content;
		height: fit-content;
		position: relative;
	}
	.div-scaled-button {
		position: absolute !important;
		top: 0;
		z-index: 0;
		transform: scaleX(1.2);
		width: 100%;
		height: 100% !important;
	}

	.trail-map-wrapper {
		width: 100%;
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--lottieWidth: 100%;
		--paddingContainer: var(--sp12x);
		--sizeMetric: var(--sp1-5x);
		--distanceMetricText: var(--sp2x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--lottieWidth: 97%;
		--paddingContainer: var(--sp10x);
		--sizeMetric: var(--sp1-5x);
		--distanceMetricText: var(--sp2x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--lottieWidth: 96%;
		--paddingContainer: var(--sp9x);
		--sizeMetric: var(--sp1-5x);
		--distanceMetricText: var(--sp2x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--lottieWidth: 96%;
		--paddingContainer: var(--sp9x);
		--sizeMetric: var(--sp1x);
		--distanceMetricText: var(--sp2x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--lottieWidth: 80.3%;
		--sizeMetric: var(--sp1x);
		--distanceMetricText: var(--sp1-5x);

		.row {
			padding: 0;
			padding-bottom: var(--sp8x);
		}

		.distance-icon-wrapper {
			padding-bottom: var(--sp6x);
		}

		.left-section {
			.info-wrapper {
				margin-top: var(--sp8x);

				.button-styles {
					/* margin: var(--sp3x) 0; */
				}

				.description {
					margin-bottom: var(--sp3x);
				}
			}
			margin-bottom: var(--sp3x);
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--lottieWidth: 99%;
		--sizeMetric: var(--sp1x);
		--distanceMetricText: var(--sp1-5x);

		.row {
			padding: 0;
			padding-bottom: var(--sp4x);
		}

		.left-section {
			.info-wrapper {
				margin-top: var(--sp6x);

				.button-styles {
					/* margin: var(--sp3x) 0; */
				}

				.description {
					margin-bottom: var(--sp3x);
				}
			}
			margin-bottom: var(--sp3x);
		}

		.distance-icon-wrapper {
			padding-bottom: var(--sp2x);
		}
	}
`;

export default MapSectionStyle;
