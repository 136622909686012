import styled from 'styled-components';

const AboutHomeSectionStyle = styled.section`
	--fakeBlockFixHeight: var(--sp6x);

	position: relative;
	padding-top: var(--sectionDistance);

	&:before {
		content: '';
		height: var(--fakeBlockFixHeight);
		position: absolute;
		left: 0;
		right: 0;
		bottom: calc(100% - var(--fakeBlockFixHeight) / 2);
		background-color: var(--whiteColor);
	}

	.wrapper-about-home {
		justify-content: center;
		text-align: center;

		.title {
			padding-bottom: var(--distanceTitle);
		}

		.description {
			padding-bottom: var(--distanceDescription);
		}
	}

	.button-wrapper {
		display: flex;
		justify-content: center;
	}

	.wrapper-button-animation {
		will-change: transform;
		position: relative;
		height: fit-content;
		width: fit-content;

		.button-wrapper {
			z-index: 100;
		}
	}
	.div-scaled-button {
		position: absolute;
		top: 0;
		z-index: 0;
		transform: scaleX(1.2);
		width: 100%;
		height: 100%;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--fakeBlockFixHeight: var(--sp4x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--fakeBlockFixHeight: var(--sp1-5x);
	}
`;

export default AboutHomeSectionStyle;
