import styled from 'styled-components';

const KeyNumbersHomeStyle = styled.section`
	--paddingTB: var(--sp13x);
	--paddingLR: var(--sp8x);
	--distanceGap: var(--sp4x);
	--distanceEveryItem: var(--sp7x);
	--distanceAbout: var(--sp11x);
	--paddingKpiLR: var(--sp8x);
	--svgSize: 61.32%;
	--sizeAllWrapper: 2560px;

	padding-top: var(--distanceAbout);
	padding-left: var(--paddingKpiLR);
	padding-right: var(--paddingKpiLR);

	.every-lottie {
		width: calc(100% / 5 - (var(--distanceEveryItem)));
		padding: var(--paddingTB) var(--paddingLR);
		background-color: var(--surfaceColor);
		border-radius: 2000px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			padding: var(--distanceGap) 0;
		}

		.lottie-wrap {
			width: var(--svgSize);
			svg {
				height: auto !important;
			}
		}
	}

	.wrapper-all-lottie {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: var(--distanceEveryItem);
		text-align: center;
	}

	.change-class-name-kpi {
		.row {
			justify-content: center;
			align-items: center;
		}

		.wrapper-all-lottie {
			max-width: var(--sizeAllWrapper);
			width: 100%;
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--paddingTB: var(--sp10x);
		--paddingLR: var(--sp6x);
		--distanceGap: var(--sp3x);
		--distanceEveryItem: var(--sp5x);
		--distanceAbout: var(--sp8x);
		--paddingKpiLR: var(--sp6x);
		--svgSize: 58.82%;
		--sizeAllWrapper: 1920px;
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--paddingTB: var(--sp8x);
		--paddingLR: var(--sp4x);
		--distanceGap: var(--sp3x);
		--distanceEveryItem: var(--sp4x);
		--distanceAbout: var(--sp7x);
		--paddingKpiLR: var(--sp6x);
		--svgSize: 57.46%;
		--sizeAllWrapper: 1536px;
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--paddingTB: var(--sp6x);
		--paddingLR: var(--sp3x);
		--distanceGap: var(--sp3x);
		--distanceEveryItem: var(--sp3x);
		--distanceAbout: var(--sp6x);
		--paddingKpiLR: var(--sp6x);
		--svgSize: 58.8%;
		--sizeAllWrapper: 1366px;
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--paddingTB: var(--sp6x);
		--paddingLR: var(--sp3x);
		--distanceGap: var(--sp3x);
		--distanceEveryItem: var(--sp3x);
		--distanceAbout: var(--sp6x);
		--paddingKpiLR: var(--sp4x);
		--svgSize: 60.6%;
		--sizeAllWrapper: 1280px;
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--paddingTB: var(--sp6x);
		--paddingLR: var(--sp3x);
		--distanceGap: var(--sp3x);
		--distanceEveryItem: var(--sp2x);
		--distanceAbout: var(--sp5x);
		--paddingKpiLR: var(--sp4x);
		--svgSize: 55.7%;
		--sizeAllWrapper: 768px;

		.every-lottie {
			width: calc(100% / 3 - var(--colPadding) * 2);
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--paddingTB: var(--sp6x);
		--paddingLR: var(--sp2x);
		--distanceGap: var(--sp2-5x);
		--distanceEveryItem: var(--sp2x);
		--distanceAbout: var(--sp4x);
		--paddingKpiLR: 0;
		--svgSize: 56.1%;
		--sizeAllWrapper: 100%;

		.every-lottie {
			width: calc(100% / 2 - var(--colPadding));
		}
	}
`;

export default KeyNumbersHomeStyle;
