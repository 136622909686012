import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap/all';

//* Helpers
import { config } from '@/helpers';

//* Style
import AboutHomeSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import KeyNumbersHome from '../KeyNumbersHome';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';

const AboutHomeSection = ({ title, description, data, isTrail = false, isHome }) => {
	//! Refs
	const titleRef = useRef();
	const descriptionRef = useRef();
	const buttonRef = useRef();

	//! State
	const [active, setActive] = useState(false);

	useEffect(() => {
		if (active) {
			gsap.to(descriptionRef.current, {
				opacity: 1,
				duration: 1.2,
				ease: 'power2.out',
				delay: 0.1,
			});
			if (!isTrail) {
				gsap.to(
					buttonRef.current.children[0],

					{
						// delay: active + 0.2,
						delay: 0.2,
						opacity: 1,
						duration: 0.8,
						ease: 'power2.out',
					}
				);
				gsap.to(buttonRef.current, {
					// delay: active + 0.2,
					delay: 0.2,
					opacity: 1,
					duration: 0.8,
					ease: 'power2.out',
				});

				gsap.to(
					buttonRef.current.children[1],

					{
						// delay: active + 0.2,
						delay: 0.2,
						scale: 1,
						duration: 1.2,
						ease: 'power2.out',
					}
				);
			}
		}
	}, [active]);

	return (
		<AboutHomeSectionStyle className={'wrapper-all-content-home-kpi'}>
			<Container
				isSection
				className={'change-class-name-kpi'}>
				<div className='wrapper-about-home row'>
					<ScrollActive
						animTitle
						allRefs={titleRef}
						percent
						percentDuration={30}
						percentFunction={setActive}>
						<Text
							tag={'h2'}
							ref={titleRef}
							className={'font-montserrat-medium h2 blue-1000-color title col-8 col-t-12 uppercase opacity-0'}>
							{title}
						</Text>
					</ScrollActive>

					<Text
						ref={descriptionRef}
						className={'font-active-grotesk-normal p2 blue-1000-color description col-8 col-t-12 opacity-0'}>
						{description}
					</Text>
				</div>

				{isTrail ? null : (
					<div className='button-wrapper'>
						<div
							ref={buttonRef}
							className='wrapper-button-about opacity-0 wrapper-button-animation'>
							<Button
								className={'button-wrapper'}
								url={config.routes.theTrail.path}
								text='seeTrial'
								btnType={'oval'}></Button>
							<Button
								btnType={'oval'}
								className='div-scaled-button reverse '>
								.S
							</Button>
						</div>
					</div>
				)}

				<KeyNumbersHome
					data={data}
					isHome={isHome}
				/>
			</Container>
		</AboutHomeSectionStyle>
	);
};

export default AboutHomeSection;
