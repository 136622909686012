import styled from 'styled-components';

const SectionTrailRegionStyle = styled.section`
	--svgSizeRegion: 100%;

	display: flex;
	justify-content: center;
	position: relative;
	margin-right: calc(var(--colPadding) * -1);
	margin-left: calc(var(--colPadding) * -4);

	svg {
		width: var(--svgSizeRegion);
		height: 100%;
		position: relative;
		z-index: 1;
		.test {
			fill: red;
		}
		.blocked-pointer-event {
			pointer-events: none;
		}
		path {
			pointer-events: none;
		}

		.hovered {
			pointer-events: all;
			@media (hover: hover) {
				transition: fill 0.5s ease-out;
				&:hover {
					fill: var(--brandLightBlueColor);
				}
			}
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {

	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {

	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {

	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		margin-top: var(--sp4x);
		margin-right: 0;
		margin-left: 0;
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		margin-top: var(--sp4x);
		margin-right: 0;
		margin-left: 0;
	}
`;

export default SectionTrailRegionStyle;
