import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap/all';

//* Helpers
import { config } from '@/helpers';

//* Style
import MapSectionStyle from './style';

//* Lottie
import mapLottie from '@/lottieFiles/map_full.json';

//* Components
import Text from '@/components/global/Text';
import Icon from '@/components/global/Icon';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';
import LottieAnimation from '@/components/global/LottieAnimation';
import SectionTrailRegion from './SectionTrailRegion';

const MapSection = ({ pageData, theTrail, className }) => {
	//! Refs
	const titleRef = useRef();
	const descriptionRef = useRef();
	const buttonRef = useRef();
	const wrapperMetric = useRef();

	//! State
	const [animStart, setAnimStart] = useState(false);

	useEffect(() => {
		if (animStart) {
			gsap.to(descriptionRef.current, {
				opacity: 1,
				ease: 'power2.out',
				duration: 1.2,
				delay: 0.2,
			});

			gsap.to(buttonRef.current, {
				delay: 0.2 + 0.1,
				opacity: 1,
				duration: 0.8,
				ease: 'power2.out',
			});

			gsap.to(
				buttonRef.current.children[0],

				{
					delay: 0.2 + 0.1,
					opacity: 1,
					duration: 0.8,
					ease: 'power2.out',
				}
			);

			gsap.to(
				buttonRef.current.children[1],

				{
					delay: 0.2 + 0.1,
					scale: 1,
					duration: 1.2,
					ease: 'power2.out',
				}
			);
			//!
			if (theTrail) {
			} else {
				gsap.to(wrapperMetric.current.children, {
					delay: 0.2,
					y: 0,
					duration: 0.8,
					opacity: 1,
					stagger: 0.08,
				});
			}
		}
	}, [animStart]);

	return (
		<MapSectionStyle className={className}>
			<Container
				full
				isSection
				className={'map-wrapper blue-1000-color-bg'}>
				<Container row>
					<div className='left-section col-5 col-t-12'>
						<div className='info-wrapper'>
							<ScrollActive
								marker={'top-=100%'}
								animTitle
								allRefs={titleRef}
								percent
								percentDuration={40}
								percentFunction={setAnimStart}>
								<Text
									tag={'h2'}
									ref={titleRef}
									className={'white-color font-montserrat-medium h2 title uppercase opacity-0'}>
									{pageData.title}
								</Text>
							</ScrollActive>

							<Text
								ref={descriptionRef}
								className={'white-color font-active-grotesk-normal p2 description opacity-0'}>
								{pageData.description}
							</Text>

							<div
								ref={buttonRef}
								className='button-wrapper-map opacity-0'>
								<Button
									btnType={'oval'}
									text={'interactiveMap'}
									url={config.routes.interactiveMap.path}
									className={'reverse button-styles'}
								/>
								<Button
									btnType={'oval'}
									className='div-scaled-button reverse '>
									.S
								</Button>
							</div>
							{theTrail ? null : (
								<div
									ref={wrapperMetric}
									className='distance-icon-wrapper wrapper-item-metrica'>
									<div className='icon-metric'>
										<Icon name='vector-stroke' />
										<Text className={'white-color font-active-grotesk-normal p4'}>{pageData.guideline_text1}</Text>
									</div>

									<div className='icon-metric icon-metric-orange'>
										<Icon name='vector-stroke' />
										<Text className={'white-color font-active-grotesk-normal p4'}>{pageData.guideline_text2}</Text>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className='empty-col col-1' />

					<div className='right-section col-6 col-t-12'>
						{theTrail ? (
							<div className='trail-map-wrapper'>
								<SectionTrailRegion />
							</div>
						) : (
							<div className='lottie-wrapper'>
								<LottieAnimation
									loop
									autoplay
									animData={mapLottie}
								/>
							</div>
						)}
					</div>
				</Container>
			</Container>
		</MapSectionStyle>
	);
};

export default MapSection;
